@import "main-bx";
.mb {
  &-100 {
    margin-bottom: 6.25rem;
  }
  &-60 {
    margin-bottom: 3.75rem;
  }
  &-40 {
    margin-bottom: 2.5rem;
  }
  &-20 {
    margin-bottom: 1.25rem;
  }
}

@media (max-width: 55.625em) {
  .mb {
    &-100 {
      margin-bottom: 2.5rem;
    }
    &-60 {
      margin-bottom: 2.5rem;
    }
  }
}
