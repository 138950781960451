@import "main-bx";

.deposit {
  &__img {
    width: 9.375rem;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }
  &__description {
    > div {
      display: flex;
      font-size: 1.125rem;
      line-height: 120%;
      font-weight: 500;
      & span {
        font-size: 1rem;
        font-weight: 900;
        padding: .62rem;
        background-color: $color_blue;
        border-radius: 0.3125rem;
        margin-right: .62rem;
        height: fit-content;
      }
    }
  }
  &__text {
    grid-column: 2/4;
    padding-left: 1.25rem;
    border-left: 0.125rem solid $color_white;
  }
}

@media (max-width: 55.625em) {
  .deposit {
    &__description {
      & > div {
        font-size: .75rem;
      }
    }
    &__text {
      grid-column: 1/2;
    }
  }
}
