@import "main-bx";

.burger {
  display: none;
}

@media (max-width: 62em) {
  .burger {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    width: 2.3125rem;
    height: 1.3125rem;
    flex-shrink: 0;
    position: relative;
    z-index: 11;
    cursor: pointer;
    span {
      height: .1875rem;
      background-color: $color_orange;
      transition: all $trns;
      &:nth-child(1) {
        width: 2.3125rem;
        margin-bottom: .375rem;
      }
      &:nth-child(2) {
        width: 2rem;
        margin-bottom: .375rem;
      }
      &:nth-child(3) {
        width: 1.6875rem;
      }
    }
    &_active {
      span {
        transition: $trns;
        &:nth-child(1) {
          width: 2rem;
          transform: rotate(45deg);
          margin-bottom: -.1rem;
        }
        &:nth-child(2) {
          width: 2rem;
          transform: rotate(-45deg);
          margin-bottom: 0;
          margin-top: -.1rem;
        }
        &:nth-child(3) {
          display: none;
        }
      }
    }
  }
}
