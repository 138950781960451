@import "main-bx";

.mobile {
  &__subtitle {
    max-width: 60rem;
    margin-left: auto;
    margin-right: auto;
  }
  &__img {
    max-width: 14rem;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 55.625em) {
  .mobile {
    &__text {
      text-align: center;
      font-size: 0.875rem;
      font-weight: 400;
    }
  }
}
