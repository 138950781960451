@import "main-bx";

.bonus {
  &__img {
    max-width: 37.5rem;
    width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }
  &__description {
    & div {
      display: flex;
      align-items: center;
      & span {
        font-size: 1rem;
        font-weight: 900;
        padding: .62rem;
        background-color: $color_blue;
        border-radius: 0.3125rem;
        margin-right: .62rem;
        height: fit-content;
      }
      &:nth-child(2) {
        grid-column: 2/4;
      }
      &:nth-child(6) {
        display: block;
      }
    }
  }
  &__text {
    &-blue {
      font-size: 2rem;
      line-height: 120%;
      font-weight: 600;
      text-transform: uppercase;
      padding: 1.25rem;
      border-radius: 0.9375rem;
      background-color: $color_blue;
    }
    &-b {
      font-size: 1.5rem;
      line-height: 120%;
      font-weight: 500;
    }
    &-c {
      grid-column: 1/4;
      font-size: 1.25rem;
      line-height: 120%;
      font-weight: 400;
      padding-left: 1.25rem;
      border-left: 0.125rem solid $color_white;
      & a {
        color: $color_blue;
        text-decoration: underline;
      }
    }
    &-d {

    }
    &-e {
      flex: 0 0 48%;
      font-size: 1.5rem;
      line-height: 120%;
      font-weight: 400;
      &_small {
        flex: 0 0 48%;
        font-size: 1.25rem;
        line-height: 120%;
        font-weight: 500;
        display: flex;
        flex-direction: column;
        & span {
          font-size: 0.8125rem;
          line-height: 120%;
          font-weight: 400;
          color: #CFCFCF;
        }
      }
    }
    &-f {

    }
  }
  &__flex {
    justify-content: space-between;
    &-col {
      flex: 0 0 48%;
    }
  }
  &__win {
    display: flex;
    padding: .62rem 0;
    & li {
      flex: 0 0 48%;
      &:first-child {
        font-size: 1.25rem;
        line-height: 120%;
        font-weight: 500;
      }
      &:last-child {
        font-size: 1.5rem;
        line-height: 120%;
        font-weight: 700;
      }
    }
  }
}

@media (max-width: 55.625em) {
  .bonus {
    &__description {
      & div {
        font-size: 1.125rem;
        &:nth-child(2) {
          grid-column: 1/2;
        }
      }
    }
    &__text {
      &-blue {
        width: fit-content;
        font-size: 0.875rem;
        line-height: 120%;
        font-weight: 400;
        margin-left: auto;
        margin-right: auto;
      }
      &-b {
        font-size: 1.125rem;
        text-align: center;
      }
      &-c {
        font-size: 0.875rem;
        grid-column: 1/2;
      }
      &-d {
        font-size: 1.375rem;
        font-weight: 600;
      }
      &-e {
        flex: 0 0 100%;
        margin-bottom: 1.25rem;
        &_small {
          flex: 0 0 100%;
          line-height: 200%;
        }
      }
    }
    &__flex {
      flex-direction: column;
      & p {
        font-size: 0.875rem;
        text-align: center;
      }
      &-col {
        margin-bottom: 1.25rem;
        & .bonus__text-e {
          text-align: left;
        }
        & .bonus__text-f {
          text-align: left;
        }
      }
    }
    &__win {
      & li {
        &:first-child {
          font-size: 1rem;
        }
        &:last-child {
          font-size: 1.25rem;
          color: $color_blue;
        }
      }
    }
  }
}
