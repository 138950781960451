@import "main-bx";

.withdrawals {
  &__img {
    width: 9.375rem;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }
  &__description {
    padding-left: 20px;
    > div {
      display: flex;
      font-size: 1.125rem;
      line-height: 120%;
      font-weight: 500;
      & span {
        font-size: 1rem;
        font-weight: 900;
        padding: .62rem;
        background-color: $color_blue;
        border-radius: 0.3125rem;
        margin-right: .62rem;
        height: fit-content;
      }
      & span.withdrawals__footnote {
        background-color: transparent;
        padding: 0;
        color: #CFCFCF;
        font-size: 0.8125rem;
        line-height: 100%;
        font-weight: 400;
        border-radius: unset;
        margin-right: 0;
        margin-top: .62rem;
        display: block;
        height: auto;
      }
    }
  }
}

@media (max-width: 55.625em) {
  .withdrawals {
    &__description {
      & > div {
        font-size: .75rem;
      }
    }
  }
}
