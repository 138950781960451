@import "main-bx";

.app-ios {
  &__wrap {

  }
  &__content {
    &.setup-ios {
      .app-ios {
        &__bottom {
          &-title {
            margin-bottom: 0.62rem;
          }
          &-subtitle {
            text-align: center;
            margin-bottom: 2.5rem;
          }
          &-footnote {
            margin-bottom: 2.5rem;
            .app-ios__bottom-item {
              padding-left: 1.25rem;
              border-left: 1px solid $color_white;
            }
          }
          &-text {
            &:first-child {
              .app-ios__bottom-img {
                width: 15.875rem;
              }
            }
            &:nth-child(3) {
              .app-ios__bottom-img {
                width: 14.75rem;
              }
            }
          }
        }
      }
    }
  }
  &__top {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 3.75rem;
  }
  &__text {
    font-size: 1.5rem;
    line-height: 120%;
    font-weight: 500;
    text-align: center;
  }
  &__img {
    max-width: 14.0625rem;
    height: auto;
  }
  &__bottom {
    &-title {
      font-size: 1.625rem;
      line-height: 120%;
      font-weight: 500;
      text-align: center;
      margin-bottom: 2.5rem;
      width: 100%;
      max-width: 35rem;
      margin-left: auto;
      margin-right: auto;
    }
    &-grid {
      margin-bottom: 2.5rem;
      padding-left: 20px;
      .app-ios__bottom-text {
        .app-ios__bottom-img {
          max-width: 20.5625rem;
          height: auto;
          margin-left: auto;
          margin-right: auto;
        }
        &:first-child {
          .app-ios__bottom-img {
            margin-top: 2.1rem;
          }
        }
        &:nth-child(2) {
          .app-ios__bottom-img {
            margin-top: 2rem;
          }
        }
        &:last-child {
          .app-ios__bottom-img {
            margin-top: 1rem;
          }
        }
      }
    }
    &-text {
      font-size: 1.125rem;
      line-height: 120%;
      font-weight: 400;
    }
  }
  &__btn {
    margin-bottom: 3.75rem;
  }
}

@media (max-width: 55.625em) {
  .app-ios {
    &__bottom {
      &-text {
        font-size: 0.875rem;
        font-weight: 400;
        & span {
          color: $color_black;
        }
      }
    }
    &__content {
      &.setup-ios {
        .app-ios {
          &__bottom {
            &-text {
              & span {
                color: $color_white;
              }
            }
          }
        }
      }
    }
  }
}
