@import "main-bx";

.intro {
  width: auto;
  height: 667px;
  background: linear-gradient(135deg, #0E117C 0%, #0F103E 100%);
  &__img {
    height: inherit;
  }
  &__content {
    width: 100%;
    max-width: 40rem;
    margin-top: calc(-50% + 12rem/2);
  }
  &__title {
    margin-bottom: 1.25rem;
  }
  &__text {
    font-size: 1.5rem;
    line-height: 1.813rem;
    margin-bottom: 2.5rem;
  }
  &__btn {
    &-app {
      margin-bottom: .3125rem;
    }
  }
  &__footnote {
    font-size: .8125rem;
    line-height: 1rem;
    font-weight: 400;
    color: #CFCFCF;
    width: 200px;
    margin-left: auto;
  }
}

@media (max-width: 75em) {
  .intro {
    position: relative;
    &__content {
      position: absolute;
      left: 20px;
      top: 187px;
      margin-top: 0;
      max-width: 35rem;
    }
    &__title {
      margin-bottom: 1.25rem;
    }
    &__text {
      font-size: 1.2rem;
      line-height: 1.813rem;
      margin-bottom: 2.5rem;
    }
    &__footnote {
      width: fit-content;
    }
  }
}

@media (max-width: 55.625em) {
  .intro {
    padding: 4.375rem 0 2.5rem;
    height: auto;
    &__img {
      display: none;
    }
    &__content {
      position: relative;
      margin: 0 auto;
      left: unset;
      top: unset;
      display: flex;
      flex-direction: column;
    }
    &__title {
      margin-bottom: 0;
      order: 1;
      font-size: 3rem;
      line-height: 120%;
    }
    &__text {
      font-size: 1.125rem;
      line-height: 120%;
      text-align: center;
      margin-bottom: 0;
      margin-top: 2rem;
      order: 4;
    }
    &__btn {
      &-app {
        order: 2;
        margin: 4rem 0 .3125rem;
      }
    }
    &__footnote {
      margin-bottom: 2.5rem;
      margin-right: auto;
      margin-top: 0.7rem;
      order: 3;
    }
  }
}
