@import "main-bx";

.header {
  padding: 0.625rem;
  background-color: $color_dark;
  z-index: 10;
  & .container {
    max-width: 1480px;
  }
  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__logo {
    width: 9.375rem;
  }
  &__nav {
    margin-right: 13rem;
  }
  &__btn-app {
    display: none;
  }
}

@media (max-width: 87em) {
  .header {
    &__nav {
      margin-right: 0;
    }
  }
}

@media (max-width: 62em) {
  .header {
    padding: 0;
    .container {
      padding: 0;
    }
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 1px;
      background-color: $color_gray;
    }
    &__content {
      flex-wrap: wrap;
      padding: 0.625rem;
      background-color: $color_dark;
    }
    &__logo {
      // width: 29.6rem;
      width: 7.625rem;
      z-index: 11;
    }
    &__nav {
      width: 20rem;
      margin: 0;
      position: absolute;
      top: 0;
      right: 0;
      height: 100vh;
      z-index: 10;
      transform: translateY(-150%);
      transition: all .4s ease;
      padding: 4rem 0 10rem;
      background: $color_dark;
      overflow-x: hidden;
      overflow-y: auto;
      &_active {
        transform: translateY(0%)
      }
      &-link {
        font-size: 1rem;
      }
    }
    &__button {
      width: auto !important;
      display: flex !important;
      margin: 4rem 3rem 0;
    }
    &__btn-app {
      display: flex;
      flex-direction: column;
      position: absolute;
      right: 10px;
      bottom: 50px;
      & .btn-app__item {
        &:last-child {
          display: none;
        }
      }
    }
  }
}
