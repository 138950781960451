@import "modules/footer/footer";
@import "modules/header/header";

//UI
@import "modules/ui/indent"; //отступы
@import "modules/ui/grid-flex"; //сетка
@import "modules/ui/typo"; //типография
@import "modules/ui/social"; //социалки

@import "modules/btn/btn";
@import "modules/burger/burger";
@import "modules/nav/nav";

//компоненты
@import "modules/intro/intro";
@import "modules/content/content";
@import "modules/app-android/app-android";
@import "modules/app-ios/app-ios";
@import "modules/mobile/mobile";
@import "modules/benefits/benefits";
@import "modules/registration/registration";
@import "modules/bonus/bonus";
@import "modules/bet/bet";
@import "modules/payment/payment";
@import "modules/deposit/deposit";
@import "modules/withdrawals/withdrawals";
@import "modules/questions/questions";
@import "modules/brands/brands";
@import "modules/error/error";
