@import "main-bx";

.bet {
  &__subtitle {
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 120%;
  }
  &__img {
    max-width: 37.5rem;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }
  &__table {
    margin: 0 auto;
    background-color: $color_dark;
    width: 100%;
    &-line {
      padding: 1.25rem 0.625rem;
      gap: 0.625rem;
      &-title {
        font-size: 1.5rem;
        padding: 0;
      }
      & p {
        flex: 1;
        padding: .62rem;
        word-break: break-word;
        margin-bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.25rem;
        line-height: 120%;
        font-weight: 500;
      }
    }
    &-head {
      &_blue {
        border-radius: 0.3125rem;
        background-color: $color_blue;
      }
      &_orange {
        border-radius: 0.3125rem;
        background-color: $color_orange;
      }
    }
    &_mob {
      display: none;
    }
  }
  &__block {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 2.5rem 0;
  }
  &__col {
    width: calc(50% - 2.5rem);
    &-title {
      font-size: 2rem;
      line-height: 120%;
      font-weight: 600;
      margin-bottom: 2.5rem;
      &_mob {
        display: none;
      }
    }
    &-sport {
      display: flex;
      align-items: center;
      padding: .62rem;
      background-color: #353D52;
      border-radius: 0.9375rem;
      & picture {
        margin-right: .62rem;
      }
    }
    &-img {
      max-width: 100%;
      margin-left: auto;
      margin-right: auto;
      &_logo {
        max-width: 20rem;
      }
    }
  }
}

@media (max-width: 55.625em) {
  .bet {
    &__text {
      font-size: 1.375rem;
      font-weight: 600;
    }
    &__table {
      display: none;
      &_mob {
        display: block;
      }
    }
    &__block {
      flex-direction: column-reverse;
      padding: 1.25rem 0;
      &:nth-child(2) {
        flex-direction: column;
      }
    }
    &__col {
      width: 100%;
      &-title {
        &_desk {
          display: none;
        }
        &_mob {
          display: block;
          margin-bottom: .62rem;
        }
      }
      &-grid {
        grid-template-columns: repeat(2, 1fr);
      }
      &-img {
        &_logo {
          max-width: 9.375rem;
        }
      }
    }
    &__subtitle {
      margin-bottom: .62rem;
      font-size: 0.875rem;
      font-weight: 400;
    }
  }
}
