@import "main-bx";

html {
    //scroll-behavior: smooth;
    //scroll-padding: 60px;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6, ul, ol, p {
    margin: 0;
    padding: 0;
}

.container {
    width: 100%;
    max-width: 1220px;
    margin: 0 auto;
    padding-left: 30px;
    padding-right: 30px;

    @media (max-width: 1380px) {
        max-width: 1200px;
        padding-left: 25px;
        padding-right: 25px;
    }

    @media (max-width: 1200px) {
        max-width: 1180px;
        padding-left: 20px;
        padding-right: 20px;
    }

    @media (max-width: 55.625em) {
        max-width: 36.25rem;
        padding-left: 0.625rem;
        padding-right: 0.625rem;
    }
    //@media (max-width: 992px) {
    //    max-width: 980px;
    //    padding-left: 10px;
    //    padding-right: 10px;
    //}
    //
    //@media (max-width: 768px) {
    //    max-width: 740px;
    //}
    //
    //@media (max-width: 576px) {
    //    max-width: 100%;
    //    padding-left: 15px;
    //    padding-right: 15px;
    //}
}

body {
    font-family: $font_main !important;
    font-size: 16px;
    line-height: 120%;
    width: 100%;
    max-width: $body_width;
    margin: 0 auto;
    background-color: $color_dark;
    color: $color_white;
    overflow-x: hidden;

    &.compensate-for-scrollbar {
        margin-right: auto !important;
    }
}

a {
    text-decoration: none;
    transition: $trns;
}

input,
button,
textarea,
fieldset {
    background: transparent;
    border: 0;
    padding: 0;
}

button {
    cursor: pointer;
    transition: $trns;
}

img {
    display: block;
    width: 100%;
    height: 100%;
    transition: $trns;
    object-fit: cover;
    max-width: 100%;
}

ul {
    list-style-type: none;
}


:focus {
    outline-style: none;
    outline-width: 0 !important;
    outline-color: none !important;
}

.none {
    display: none;
}
