@import "main-bx";

.btn {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  padding: 23px 31.5px;
  font-family: $font_base;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 120%;

  text-align: center;
  color: $color_white;
  background: $gradient_green;
  border-radius: 0.625rem;
  transition: 0.35s all;
  cursor: pointer;
  & svg {
    margin-left: 0.62rem;
  }
  &-app {
    &__list {
      display: flex;
      justify-content: space-between;
    }
    &__item {
      width: 12.5rem;
      border-radius: 0.625rem;
      padding: 0.625rem 1.25rem;
      background-color: $color_black;
      display: flex;
      align-items: center;
      & svg {
        margin-right: .41rem;
      }
    }
    &__img {
      width: auto;
      height: 33px;
    }
    &__text {
      color: $color_gray4;
      font-size: 0.875rem;
      font-weight: 500;
      & span {
        font-size: 1.25rem;
        color: $color_white;
      }
    }
  }
}

@media (max-width: 75em) {
  .btn {
    &-app {
      &__item {
        width: 11.3rem;
        padding: .6rem;
      }
    }
  }
}

@media (max-width: 55.625em) {
  .btn {
    &-app {
      &__list {
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      &__item {
        width: 16rem;
        padding: 1rem 4.5rem 1rem 1.5rem;
        background: $gradient_green;
        &:not(:last-child) {
          margin-bottom: 2rem;
        }
      }
    }
  }
}
