@import "main-bx";

.content {
  &__block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2.5rem 0;
  }
  &__col {
    width: calc(50% - 2.5rem);
  }
  &__text {
    font-size: 1.5rem;
    line-height: 120%;
    font-weight: 400;
    &:nth-child(2) {
      margin-top: 1.25rem;
    }
  }
  &__images {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.63rem 0;
    &-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      &:first-child {
        margin-bottom: 2.5rem;
      }
    }
    &-item {
      margin: 0 1.25rem;
    }
  }
}

@media (max-width: 55.625em) {
  .content {
    &__col {
      width: 100%;
      max-width: 35rem;
      & img {
        width: 100%;
        max-width: 18.75rem;
        margin: 1.25rem auto 0;
      }
    }
    &__block {
      flex-direction: column-reverse;
      padding: 0;
      margin-bottom: 2.5rem;
      &:nth-child(2) {
        flex-direction: column;
      }
    }
    &__images {
      margin-top: 2.5rem;
      &-item {
        margin: 0 .31rem;
        & svg {
          width: 5rem;
          height: 5rem;
        }
      }
    }
    &__text {
      font-size: 1.125rem;
    }
  }
}
