@import "main-bx";

.brands {
  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  &__item {
    margin: 0 .62rem 1.25rem;
  }
}
