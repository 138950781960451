@import "main-bx";

.questions {
  &__title {
    margin-bottom: 3.75rem;
  }
  &__item {
    &-wrap {
      border-radius: 0.5rem;
      border: 1px solid #D3D3D3;
      box-shadow: 0px 20px 50px 0px rgba(255, 255, 255, 0.06);
      margin-bottom: 3.75rem;
      &.show {
        .questions__item-arrow {
          background-color: $color_dark;
          svg {
            color: $color_white;
            transform: rotate(180deg);
          }
        }
        .questions__item-item {
          border-bottom: 1px solid $color_white;
        }
      }
    }
    &-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1.88rem;
      cursor: pointer;
      & span {
        margin-left: .62rem;
      }
    }
    &-block {
      display: flex;
      flex-direction: column;
      padding: 1.88rem;
    }
  }
}

@media (max-width: 55.625em) {
  .questions {
    &__item {
      &-wrap {
        margin-bottom: 1.25rem;
      }
      &-text {
        font-size: 0.875rem;
        font-weight: 400;
      }
    }
  }
}
