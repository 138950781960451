@import "main-bx";

.footer {

  &__btn-app {
    &.btn-app__list {
      justify-content: center;
    }
    .btn-app__item {
      &:not(:last-child) {
        margin-right: 1.25rem;
      }
    }
  }
  &__social {
    display: flex;
    align-items: center;
    justify-content: center;

    &-link {
      &:not(:first-child) {
        margin-left: 20px;
      }
      @media (max-width: 1023px) {
        margin-left: 0;
      }
    }
  }
  &__cryptopay {
    display: flex;
    justify-content: center;
    align-items: center;
    &-img {
      margin-right: 1.25rem;
    }
  }
  &__bitcoin {
    display: flex;
    align-items: center;
    &-img {
      margin-right: .62rem;
      width: 1.25rem;
      height: 1.25rem;
    }
    &-text {
      display: flex;
      flex-direction: column;
      font-size: .5rem;
      font-weight: 500;
      line-height: 120%;
    }
  }
  &__text {
    color: #5C6374;
    font-size: 1rem;
    line-height: 120%;
    font-weight: 700;
    text-align: center;
  }
  &__footnote {
    margin-top: .5rem;
    color: #CFCFCF;
  }
}

@media (max-width: 55.625em) {
  .footer {
    &__btn-app {
      & .btn-app__item {
        margin-right: 0 !important;
      }
    }
    &__footnote {
      text-align: center;
      font-size: 0.8125rem;
      font-weight: 400;
    }
    &__text {
      font-size: 0.75rem;
    }
  }
}
