@import "main-bx";

.social {
  width: 3.75rem;
  height: 3.75rem;
  padding: 1rem;
  background: $color_white;
  border-radius: 40px;
  border: 1px solid rgba(2, 40, 59, 0.30);
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 767px) {
    width: 3.125rem;
    height: 3.125rem;
    padding: .75rem;
  }
  & svg {
    width: 2rem;
    height: 2rem;
    fill: rgba(2, 40, 59, 1);
    stroke: rgba(2, 40, 59, 1);
    @media (max-width: 767px) {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
  &_tg {
    &:hover {
      background: #2AABEE;
      border-color: #2AABEE;
      & svg {
        fill: $color_white;
        stroke: $color_white;
      }
    }
  }
  &_vk {
    &:hover {
      background: #07F;
      border-color: #07F;
      & svg {
        fill: $color_white;
        stroke: $color_white;
      }
    }
  }
  &_wa {
    &:hover {
      background: #25D366;
      border-color: #25D366;
      & svg {
        fill: $color_white;
        stroke: $color_white;
      }
    }
  }
  &_viber {
    &:hover {
      background: #7360F2;
      border-color: #7360F2;
      & svg {
        fill: $color_white;
        stroke: $color_white;
      }
    }
  }
  &_classmate {
    &:hover {
      background: #F70;
      border-color: #F70;
      & svg {
        fill: $color_white;
        stroke: $color_white;
      }
    }
  }
}
