@import "main-bx";

.error {
  background-color: $color_dark;
  position: relative;
  height: 100vh;
  &__logo {
    display: block;
    width: 9.375rem;
    padding: .62rem;
  }
  &__content {
    max-width: 47.25rem;
    position: absolute;
    top: 50%;
    left: calc(50% - 23.6rem);
    margin-top: -8.625em;
  }
  &__oops {
    color: $color_yellow;
    font-size: 5.25rem;
    text-align: center;
    line-height: 120%;
    font-weight: 900;
  }
  &__text {
    font-size: 2.625rem;
    font-weight: 600;
    line-height: 120%;
    text-align: center;
  }
  &__btn {

  }
}

@media (max-width: 55.625em) {
  .error {
    &__content {
      max-width: 18.75rem;
      left: calc(50% - 9.3rem);
    }
    &__oops {
      font-size: 3rem;
    }
    &__text {
      font-size: 1.75rem;
    }
    &__btn {

    }
  }
}
