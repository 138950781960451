@import "main-bx";

h1 {
  font-size: 2.625rem;
  line-height: 2.875rem;
  font-weight: 600;
  @media (max-width: 75em) {
    font-size: 2.2rem;
    font-weight: 600;
  }
  @media (max-width: 55.625em) {
    font-size: 1.75rem;
    font-weight: 700;
    text-align: center;
  }
}

h2 {
  font-size: 2.625rem;
  line-height: 120%;
  font-weight: 600;
  text-align: center;
  margin-bottom: 1.25rem;
}

h3 {
  font-size: 1.625rem;
  line-height: 120%;
  font-weight: 500;
  text-align: center;
  margin-bottom: 2.5rem;
}

h4 {
  font-size: 24px;
  font-weight: 500;
  line-height: 24px;
  @media (max-width: 55.625em) {
    font-size: 1rem;
    font-weight: 600;
  }
}

.title-1 {
  font-size: 2.625rem;
  line-height: 2.875rem;
  font-weight: 600;
}

.title-2 {
  font-size: 1.625rem;
  line-height: 120%;
  font-weight: 500;
  text-align: center;
  margin-bottom: 2.5rem;
}

.subtitle {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 120%;
  text-align: center;
  margin-bottom: 2.5rem;
}

@media (max-width: 55.625em) {
  .title-1 {
    font-size: 1.75rem;
    font-weight: 700;
  }
  .subtitle {
    font-size: 1.125rem;
    margin-bottom: 1.25rem;
  }
}
