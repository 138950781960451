@import "main-bx";

.registration {
  &__subtitle {
    max-width: 60rem;
    margin-left: auto;
    margin-right: auto;
  }
  &__img {
    max-width: 37.5rem;
    margin-left: auto;
    margin-right: auto;
  }
  &__description {
    padding-left: 20px;
  }
  &__text {
    max-width: 57.5rem;
    margin-left: auto;
    margin-right: auto;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 120%;
    text-align: center;
  }
}

@media (max-width: 55.625em) {
  .registration {
    &__description {
      > li {
        font-size: 0.875rem;
        font-weight: 400;
      }
    }
    &__text {
      font-size: 0.875rem;
      font-weight: 400;
    }
  }
}
