@import "main-bx";

.app-android {
  &__wrap {

  }
  &__content {
    &.setup {
      .app-android {
        &__bottom {
          &-title {
            margin-bottom: 0.62rem;
          }
          &-subtitle {
            text-align: center;
            margin-bottom: 2.5rem;
          }
          //&-text {
          //  &:last-child {
          //    grid-column: 3/5;
          //    padding-left: 1.25rem;
          //    border-left: 1px solid $color_white;
          //  }
          //}
          &-item {
            .app-android__bottom-text {
              padding-left: 1.25rem;
              border-left: 1px solid $color_white;
            }
          }
        }
      }
    }
  }
  &__top {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 3.75rem;
  }
  &__text {
    font-size: 1.5rem;
    line-height: 120%;
    font-weight: 500;
    text-align: center;
  }
  &__img {
    width: 19.7rem;
    height: auto;
  }
  &__bottom {
    &-title {
      width: 100%;
      max-width: 35rem;
      margin-left: auto;
      margin-right: auto;
    }
    &-grid {
      margin-bottom: 2.5rem;
      padding-left: 20px;
    }
    &-text {
      &:nth-child(2) {
        .app-android__bottom-img {
          margin-top: 2.5rem;
        }
      }
      &:not(:nth-child(2)) {
        .app-android__bottom-img {
          width: 13.4375rem;
          height: auto;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
    &-text {
      font-size: 1.125rem;
      line-height: 120%;
      font-weight: 400;
    }
  }
  &__btn {
    margin-bottom: 3.75rem;
  }
}

@media (max-width: 55.625em) {
  .app-android {
    &__img {
      width: 15.84181rem;
    }
    &__bottom {
      &-text {
        font-size: 0.875rem;
        font-weight: 400;
      }
    }
    &__content {
      &.setup {
        .app-android {
          &__bottom {
            &-text {
              &:last-child {
                grid-column: 1/2;
              }
            }
            &-text {
              & span {
                color: $color_black;
              }
            }
          }
        }
      }
    }
  }
}
