@import "main-bx";

.benefits {
  &__subtitle {
    max-width: 60rem;
    margin-left: auto;
    margin-right: auto;
  }
  &__img {
    max-width: 37.5rem;
    margin-left: auto;
    margin-right: auto;
  }
  &__text {
    max-width: 57.5rem;
    margin-left: auto;
    margin-right: auto;
    font-size: 1.25rem;
    line-height: 120%;
    font-weight: 400;
    text-align: center;
  }
  &__subtitle {
    font-weight: 400;
  }
  &__table {
    margin: 0 auto;
    background-color: $color_dark;
    width: 100%;
    &-line {
      padding: 1.25rem 0.625rem;
      gap: 0.625rem;
      &-title {
        font-size: 1.5rem;
        padding: 0;
      }
      & p {
        flex: 1;
        padding: .62rem;
        word-break: break-word;
        margin-bottom: 0;
        display: flex;
        align-items: center;
        font-size: 1.25rem;
        line-height: 120%;
        font-weight: 500;
      }
    }
    &-head {
      &_blue {
        border-radius: 0.3125rem;
        background-color: $color_blue;
      }
      &_orange {
        border-radius: 0.3125rem;
        background-color: $color_orange;
      }
    }
    &_mob {
      display: none;
    }
  }
}

@media (max-width: 55.625em) {
  .benefits {
    &__description {
      > p {
        font-size: 0.875rem;
        font-weight: 400;
      }
    }
    &__text {
      font-size: 0.875rem;
      font-weight: 400;
    }
    &__table {
      display: none;
      &_mob {
        display: block;
        &-item {
          padding: .5rem 0;
          border-bottom: 1px solid #EBEBEB;
        }
        &-head {
          font-size: 1rem;
          line-height: 120%;
          font-weight: 700;
          text-align: center;
          padding: .75rem 0;
        }
        &-subtitle {
          font-size: 0.75rem;
          line-height: 120%;
          font-weight: 500;
          display: flex;
          justify-content: space-between;
          & li {
            padding: .62rem;
            flex: 0 0 48%;
            text-align: center;
          }
          &_blue {
            border-radius: 0.3125rem;
            background-color: $color_blue;
          }
          &_orange {
            border-radius: 0.3125rem;
            background-color: $color_orange;
          }
        }
        &-desc {
          font-size: 0.75rem;
          line-height: 120%;
          font-weight: 500;
          display: flex;
          justify-content: space-between;
          & li {
            padding: .62rem;
            flex: 0 0 48%;
            text-align: center;
          }
        }
      }
    }
  }
}
