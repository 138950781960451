@import "main-bx";

.nav {
  &__list {
    display: flex;
    align-items: center;
    > li {
      &:not(:last-child) {
        margin-right: 2.5rem;
      }
    }
  }
  &__link {
    font-size: 1.25rem;
    font-weight: 700;
    color: $color_white;
    &_active {
      color: $color_yellow;
    }
    &:hover {
      color: $color_yellow;
    }
  }
}

@media (max-width: 65em) {
  .nav {
    &__list {
      > li {
        &:not(:last-child) {
          margin-right: 2.0rem;
        }
      }
    }
  }
}

@media (max-width: 62em) {
  .nav {
    &__list {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      > li {
        font-size: 2.8rem;
        &:not(:last-child) {
          margin-right: 0;
        }
      }
    }
    &__link {
      position: unset;
      padding: .25rem .625rem;
      display: block;
      &_active {
        font-size: 2.8rem;
        font-weight: 400;
        color: $color_white;
      }
    }
  }
}
