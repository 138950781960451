@import "main-bx";

.payment {
  &__img {
    max-width: 35rem;
    margin-left: auto;
    margin-right: auto;
  }
  &__subtitle {
    max-width: 57.5rem;
    margin-left: auto;
    margin-right: auto;
    &_mob {
      display: none;
    }
  }
  &__table {
    margin: 0 auto 3.75rem;
    background-color: $color_dark;
    width: 100%;
    &-line {
      padding: 1.25rem 0.625rem;
      gap: 0.625rem;
      &-title {
        font-size: 1.5rem;
        padding: 0;
        & p {
          background-color: #474F66;
          border-radius: 0.3125rem;
        }
      }
      & p {
        flex: 1;
        padding: .62rem;
        word-break: break-word;
        margin-bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.25rem;
        line-height: 120%;
        font-weight: 500;
      }
    }
    &-head {
      &_blue {
        border-radius: 0.3125rem;
        background-color: $color_blue;
      }
      &_orange {
        border-radius: 0.3125rem;
        background-color: $color_orange;
      }
    }
    &_mob {
      display: none;
    }
  }
}

@media (max-width: 55.625em) {
  .payment {
    &__img {
      max-width: 25rem;
    }
    &__subtitle {
      display: none;
      &_mob {
        display: block;
      }
    }
    &__table {
      display: none;
      &_mob {
        display: block;
        margin-bottom: 2.5rem;
        &-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: .62rem;
          border-bottom: 1px solid $color_white2;
        }
        &-title {
          font-size: 0.75rem;
          line-height: 120%;
          font-weight: 500;
          color: #878787;
        }
        &-value {
          font-size: 0.75rem;
          line-height: 120%;
          font-weight: 500;
          &-blue {
            font-size: 1rem;
            line-height: 120%;
            font-weight: 700;
            color: $color_blue;
          }
        }
      }
    }
  }
}
