$font_base: sans-serif;
$font_main: "Inter", $font_base;

$color_white: #FFF;
$color_white2: #B9C5E1;
$color_black: #000;
$color_dark: #1D222F;
$color_blue: #0056D7;
$color_yellow: #FFDC14;
$color_orange: #EA8C00;
$color_gray: #313643;
$color_gray2: #353D52;
$color_gray3: #5C6374;
$color_gray4: #7A7A7A;
$gradient_green: linear-gradient(63deg, #2DD078 0%, #00AE8B 100%), #000;


$breaks: (
        desktop_lg: 1600px,
        desktop: 1440px,
        laptop: 1380px,
        tablet: 1100px,
        tablet_md: 1024px,
        mobile: 768px,
        mobile_sm: 575px,
        mobile_xs: 479px,
        mobile_xxs: 375px,
);

$container: (
        desktop_lg: 1420px,
        desktop: 1400px,
        laptop: 1024px,
        tablet: 768px,
        mobile: 345px,
        mobile_xs: 280px,
);

$body_width: 1920px;


$trns: all 0.3s linear;
