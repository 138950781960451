@import "main-bx";

.grid {
  display: grid;
  grid-gap: 2.5rem;
  &-2 {
    grid-template-columns: repeat(2, 1fr);
  }
  &-3 {
    grid-template-columns: repeat(3, 1fr);
  }
  &-4 {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 55.625em) {
  .grid {
    grid-gap: 1rem;
    &-2 {
      grid-template-columns: repeat(1, 1fr);
    }
    &-3 {
      grid-template-columns: repeat(1, 1fr);
    }
    &-4 {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}

.flex {
  display: flex;
  &_gray {
    background-color: $color_gray;
  }
}
